@import '../../../styles/customMediaQueries.css';

.bgPurpose {
    display: none;

    @media (--viewportLarge) {
        display: block;
        background-color: var(--marketplaceColor);
        width: 583px;
        height: 583px;
        position: absolute;
        border-radius: 50%;
        left: -207px;
        top: 32.99px;
        z-index: 1;
    }
}

.customWhySection {}

.contentContainer {
    width: 90%;
    margin: auto;

    @media (--viewportLarge) {
        display: flex;
        gap: 50px;
        align-items: center;
        position: relative;
        z-index: 2;
    }
}

.contentContainer>* {
    @media (--viewportLarge) {
        flex: 0 0 calc(50% - 25px);
        box-sizing: border-box;
    }
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (--viewportLarge) {
        display: grid;
        gap: 35px;
        width: calc((100% / 2) - 10px);
    }
}

.sectionImg {
    display: none;

    @media (--viewportLarge) {
        display: block;
        width: calc((100% / 2) - 10px);
    }
}

.sectionImg img {
    width: 100%;
}

.sectionTitle {}

.detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.detailBlock {
    display: flex;
    gap: 17px;
    align-items: flex-start;
}

.detailContent {}

.detailTitle {
    color: var(--marketplaceColor);
    margin: auto;
}