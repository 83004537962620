@import '../../../styles/customMediaQueries.css';

.customWhatWeDo {
    position: relative;
    background-color: #FFF2EB;
    overflow: hidden;
    margin-bottom: -32px;

    @media (--viewportMedium) {
        margin-bottom: -64px;
    }

    @media (--viewportLarge) {}
}

.theEllipse {
    background-color: var(--marketplaceColor);
    width: 710px;
    height: 710px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -476px;
    border-radius: 50%;

    @media (--viewportLarge) {
        bottom: 50%;
        right: -207px;
        left: unset;
        transform: translateY(50%);
    }
}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 1;

    @media (--viewportLarge) {
        flex-direction: row;
    }
}

.title {}

.contentDetails {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;

    @media (--viewportLarge) {
        flex-direction: row;
    }
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (--viewportLarge) {
        width: 50%;
        padding-bottom: 50px;
    }
}

.detailBox {
    display: flex;
    gap: 17px;
    align-items: flex-start;
}

.detailContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detailContent>* {
    margin: 0;
}

.detailTitle {
    color: var(--marketplaceColor);
}

.imgBox {
    width: 237px;
    margin: 0 auto;

    @media (--viewportMedium) {
        width: 350px;
    }

    @media (--viewportLarge) {
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

.peopleMobile {
    @media (--viewportLarge) {
        display: none;
    }
}

.peopleDesktop {
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}