@import '../../../styles/customMediaQueries.css';

.cta1Section {
    background: rgba(237, 244, 243, 0.86) url('../../../assets/keychain-house.jpg') no-repeat center center / cover !important;
    background-blend-mode: overlay;
}

.cta1Section>div {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (--viewportLargeWithPaddings) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    background: rgba(3, 99, 81, 0.10);

    @media (--viewportMedium) {
        background: transparent;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
    }
}

.textsWrapper {
    color: var(--marketplaceColor);
    text-align: center;

    @media (--viewportLargeWithPaddings) {
        text-align: left;
        width: 70%;
    }
}

.btnStay {
    height: 56px;
    padding: 10px;
    border-radius: 4px;
    background: var(--marketplaceColor);

    @media (--viewportMedium) {
        width: 210px;
    }
}