@import '../../../styles/customMediaQueries.css';

.payProtectionSection {
    background-color: var(--colorWhite);
}

.contentContainer {
    width: 90%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border-radius: 6px;
    background: linear-gradient(90deg, #FFE4D2 0%, #ECFFFB 100%);

    @media (--viewportMedium) {
        padding: 20px 50px;
        gap: 24px;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    @media (--viewportLarge) {
        width: 70%;
    }
}

.leftColumn {}

.rightColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;

    @media (--viewportMedium) {
        text-align: unset;
    }

    @media (--viewportLarge) {
        width: 70%;
    }
}

.title {
    margin: 0;
}