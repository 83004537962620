@import '../../../styles/customMediaQueries.css';

.customHeroSection {
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(3, 43, 35, 0.85) !important;
    overflow: hidden;
}

.bgVideo {

    @media (--viewportLarge) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (--viewportLarge) {
        flex-direction: unset;
        gap: 100px;
        align-items: center;
    }
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (--viewportLarge) {
        flex-direction: row;
        gap: 100px;
    }
}

.leftColumn {
    color: var(--colorWhite);
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (--viewportMedium) {
        width: 100%;
    }

    @media (--viewportLarge) {
        align-items: flex-start;
    }
}

.textsWrapper {}

.textTitle {
    @media (--viewportLarge) {
        font-size: 50px;
    }
}

.textDesc {}

.statisticsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (--viewportMedium) {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.statisticsWrapper>div {
    flex: 0 0 calc(50% - 20px);
    box-sizing: border-box;

    @media (--viewportMedium) {
        flex: 0 0 calc((100% / 3) - 20px);
    }

    @media (--viewportLargeWithPaddings) {
        flex: 0 0 calc((100% / 3) - (-50px));
    }
}

.textLarge {
    font-size: 24px;
    font-weight: 700;
}

.textSmall {
    font-size: 14px;
    font-weight: 400;
}

.linkBtn:hover {
    text-decoration: unset;
}

.customButton {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.rightColumn {
    background-color: var(--colorWhite);
    box-shadow: 13px 12px 0px 0px var(--marketplaceColor);
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (--viewportMedium) {
        width: 100%;
        padding: 30px;
    }
}

.formContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}