@import '../../../styles/customMediaQueries.css';

.customAccommFor {}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.title {}

.accommodationContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (--viewportMedium) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.accommodationsBlock {
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 23px 0px rgba(143, 143, 143, 0.12);
    background-color: var(--colorWhite);

    @media (--viewportMedium) {
        width: calc((100% / 2) - 10px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (--viewportLarge) {
        width: calc((100% / 3) - 15px);
    }
}

.accommodationsBlock img {
    width: 100%;
    border-radius: 5px;
    height: 250px;
    object-fit: cover;
}

.btnFind {
    color: var(--colorPrimaryButton);
}