@import '../../../styles/customMediaQueries.css';

.customHowSection {
    background-color: var(--colorWhite);
}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.headingContainer {
    text-align: center;
}

.sectionTitle {}

.detailsContainer {
    display: flex;
    gap: 20px;
    flex-direction: column;

    @media (--viewportLarge) {
        flex-direction: row;
        gap: 50px;
    }
}

.detailBlock {
    padding: 20px;
    border-radius: 5px;

    @media (--viewportLarge) {
        padding: 30px;
        width: calc(100% / 3);
    }
}

.detailBlock:nth-child(odd) {
    background-color: #FFF2EB;
}

.detailBlock:nth-child(even) {
    background-color: #EDF4F3;
}

.detailTitle {
    color: var(--marketplaceColor);
}