@import '../../../styles/customMediaQueries.css';

.compWeWorkWith {
    background-color: #F6F6F6 !important;
}

.compWeWorkWith>div {
    width: 90%;
    margin: auto;
}

.logosBlock {
    width: calc(50% - 20px);
}

.title {
    text-align: center;
}

.logosWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;

    @media (--viewportMedium) {
        flex-wrap: unset;
        margin-top: 50px;
    }
}

.logosBlock img {
    height: 50px;
    width: 100%;
    object-fit: contain;
}