@import '../../../styles/customMediaQueries.css';

.greenCTA {
    background: linear-gradient(90deg, rgba(2, 30, 25, 0.87) 0%, rgba(84, 134, 124, 0.87) 100%), url('../../../assets/bg-cta2.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.greenCTA>div {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.title {
    color: var(--colorWhite);
    text-align: center;
}

.theBtn {
    height: 56px;
    padding: 10px;
}