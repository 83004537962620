@import '../../../styles/customMediaQueries.css';

.customFeaturedAccomm {}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.listingsWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 20px;
    }
}

.theListing {
    @media (--viewportMedium) {
        display: block;
        width: 100%;
    }
}

.theListing>div {
    display: block;
}

.theListing>div>div {
    @media (--viewportMedium) {
        width: 100%;
    }
}

.headingContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.sectionTitle {}

.linkBtn {
    margin-top: 50px;
}

.linkBtn:hover {
    text-decoration: unset;
}

.customButton {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
}