@import '../../../styles/customMediaQueries.css';

.levelServiceSection {
    background-color: var(--colorWhite);
}

.contentContainer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.textsWrapper {
    text-align: center;
}

.title {}

.detailsContainer {
    display: grid;
    gap: 20px;

    @media (--viewportMedium) {
        display: flex;
        gap: 20px;
    }
}

.detailsBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    background-color: var(--colorWhite);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 23px 0px rgba(143, 143, 143, 0.12);

    @media (--viewportMedium) {
        width: calc((100% / 3) - (20px / 3));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.icons {
    width: unset;
}

.detailImage {
    width: 100%;
    border-radius: 5px;
    height: 250px;
    object-fit: cover;
}